.lbm58tn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:start;-webkit-justify-content:start;-ms-flex-pack:start;justify-content:start;background:#fff;box-shadow:0 4px 19.5556px rgba(63,114,91,0.1);border-radius:7px;padding:40px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;height:260px;width:312px;text-align:center;position:relative;}
.i1ctsaru{margin-bottom:var(--i1ctsaru-0);display:var(--i1ctsaru-1);}.i1ctsaru > div,.i1ctsaru > button{width:100%;}.i1ctsaru:last-child{margin-bottom:0;}
.ar8lurw{position:fixed;height:100%;width:100%;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:0 5%;top:0;left:0;}
.d1f0mnvg{padding:24px 0 24px 0;-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.l1vc21zc{font-family:SF Pro Display Mono,sans-serif;font-size:22px;border:0;-webkit-letter-spacing:3px;-moz-letter-spacing:3px;-ms-letter-spacing:3px;letter-spacing:3px;outline:0;padding:0;width:214px;}
.cmybl9v.l1vc21zc{text-align:center;}
.p1qtndth{font-size:22px;-webkit-letter-spacing:3px;-moz-letter-spacing:3px;-ms-letter-spacing:3px;letter-spacing:3px;}
.prua8ca{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.biopg24:before{content:'';position:absolute;left:12px;top:50%;margin-top:-6px;border-right:7px solid #727382;border-top:6px solid transparent;border-bottom:6px solid transparent;}.biopg24:after{content:'';position:absolute;left:14px;top:50%;margin-top:-6px;border-right:7px solid #EEF1FA;border-top:6px solid transparent;border-bottom:6px solid transparent;}
.l3k7cgd{margin:0;-webkit-flex:0 0 36px;-ms-flex:0 0 36px;flex:0 0 36px;}
.l4w0mac{width:100%;}
.ll48yu4{-webkit-letter-spacing:3px;-moz-letter-spacing:3px;-ms-letter-spacing:3px;letter-spacing:3px;white-space:nowrap;}
