@font-face {
    font-family: 'SF Pro Display';
    src: url('/static/fonts/SFProDisplay-Bold.woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('/static/fonts/SFProDisplay-Semibold.woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('/static/fonts/SFProDisplay-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display Mono';
    src: url('/static/fonts/SFMono-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

html, body {
    font-size: 14px;
    font-family: SF Pro Display, sans-serif;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    color: #727382;
    background-color: #25262A;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.my-masonry-grid {
    display: flex;
    margin-left: -30px;
    width: 100%;
}
.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

.my-masonry-grid_column > div {
    margin-bottom: 30px;
}

/* HELP DESK */
#atlwdg-trigger {
    right: 32px;
    display: flex;
    padding: 8px 12px;
    border-radius: 8px 8px 0 0;
    background: #4566f6;
    box-shadow: none;
    color: #FFFF !important;
    font-family: SF Pro Display, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0;
    z-index: 10000000 !important;
    border: none;
    transition: 0.2s ease background;
}
#atlwdg-trigger:hover {
    background: #1B66DB;
}

#atlwdg-container, #atlwdg-blanket {
    z-index: 10000011 !important;
}
@media screen and (max-width: 830px) {
    #atlwdg-trigger, #atlwdg-blanket, #atlwdg-container {
        display: none !important;
    }
}