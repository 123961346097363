.c1p9oncy.wj9v6yf{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 0 0 0;}
.h1uz1y3x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-bottom:32px;border-bottom:1px solid #48494D;}@media (max-width:1550px){.h1uz1y3x{padding-bottom:20px;}}
.b10odh3q{display:grid;grid-template-columns:repeat(3,1fr);grid-column-gap:32px;grid-row-gap:32px;padding-top:32px;}@media (max-width:1920px){.b10odh3q{grid-column-gap:20px;grid-row-gap:20px;grid-template-columns:repeat(2,1fr);}}
.bfoylmx{display:grid;grid-template-columns:repeat(3,1fr);grid-column-gap:32px;grid-row-gap:0px;padding-top:32px;}
.ckx4zrt.t1w41ra5{font-size:24px;font-weight:bold;padding:0;}@media (max-width:1550px){.ckx4zrt.t1w41ra5{font-size:20px;}}
.s15lvz6t.t1w41ra5{font-size:20px;color:#fff;font-weight:bold;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}@media (max-width:1550px){.s15lvz6t.t1w41ra5{font-size:17px;}}
.n3lry6s{position:absolute;right:0;font-size:14px;color:#727382;z-index:5;}
.cc62k9o.wj9v6yf{min-width:250px;background:#25262a;color:#fff;border-radius:8px;padding:32px 32px 22px 32px;}@media (max-width:1550px){.cc62k9o.wj9v6yf{padding:20px 20px 5px 20px;}}
.c19ym9tz.wj9v6yf{background:#25262a;color:#fff;border-radius:8px;padding:32px;}
.cm3j4bs.cc62k9o.wj9v6yf{padding:32px 0 16px 0;}
.cap56lf{position:relative;width:100%;}
.g1a3xkbf{font-size:18px;overflow-wrap:anywhere;}@media (max-width:1800px){.g1a3xkbf{font-size:16px;}}@media (max-width:1550px){.g1a3xkbf{font-size:14px;}}
