.wlqip70{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;width:100%;}
.i1xmk18o{display:block;}
.i1ib0qp{width:100%;height:1px;background:#48494d;}
.i11mssge{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.i50ry0h{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.ig8chq2{width:21px;}
.i92s1j2{font-size:18px;font-weight:500;line-height:21px;-webkit-letter-spacing:-0.36px;-moz-letter-spacing:-0.36px;-ms-letter-spacing:-0.36px;letter-spacing:-0.36px;color:#fff;margin:0 10px 0 10px;}
.irekymu{font-size:14px;font-weight:300;line-height:21px;-webkit-letter-spacing:-0.28px;-moz-letter-spacing:-0.28px;-ms-letter-spacing:-0.28px;letter-spacing:-0.28px;color:#23ff88;}
.ic88r5s{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;}
.i11lbuwo{font-size:14px;font-style:normal;font-weight:600;line-height:21px;-webkit-letter-spacing:-0.28px;-moz-letter-spacing:-0.28px;-ms-letter-spacing:-0.28px;letter-spacing:-0.28px;color:#67d1ff;}
.i1s063eu{font-size:22px;font-weight:600;line-height:21px;-webkit-letter-spacing:-0.44px;-moz-letter-spacing:-0.44px;-ms-letter-spacing:-0.44px;letter-spacing:-0.44px;color:#fff;margin:0;}
.i166pjhg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:5px;margin-top:12px;}
.ipxbm61{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.iyyn48k{width:210px;font-size:14px;font-style:normal;font-weight:400;line-height:16px;color:#969696;}
.i16kr8lh{display:block;width:100%;}
.iipgxr1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:8px;}
.i1ppdop7{width:100%;margin-top:1px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.iezf2ze{width:var(--iezf2ze-0);}
.i1ynchtf{height:6px;width:100%;background-color:var(--i1ynchtf-0);}
.i1y9mwlo{width:auto;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:7px;}
.i19aawtt{font-size:12px;font-style:normal;font-weight:600;line-height:1;-webkit-letter-spacing:-0.24px;-moz-letter-spacing:-0.24px;-ms-letter-spacing:-0.24px;letter-spacing:-0.24px;color:var(--i19aawtt-0);}
.iuii0j0{font-size:12px;font-style:normal;font-weight:400;line-height:1;margin:0;color:#969696;}
.i1fb5ybk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:12px;}
.i626o6g{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px;}
.ixmr89a{font-size:16px;color:#777782;line-height:1;}
